import request from '@/utils/request';
import Qs from 'qs'

/**
 *          用户
 */
/** 用户列表 */
export function userList(data) {
    return request({
        url: '/system/user/list',//请求路径
        method: 'post',
        data
    });
}

/** 新增用户 */
export function addUser(data) {
    return request({
        url: '/system/user/add',//请求路径
        method: 'post',
        data
    });
}

/** 删除用户 */
export function delUser(data) {
    return request({
        url: '/system/user/del/'+data,//请求路径
        method: 'get',
        // params: data
    });
}

/** 编辑用户 */
export function editUser(data) {
    return request({
        url: '/system/user/edit',//请求路径
        method: 'post',
        data
    });
}

/** 重置密码 */
export function resetPwd(data) {
    return request({
        url: '/system/user/resetPwd/'+data,//请求路径
        method: 'get',
        // params: data
    });
}

/** 登出 */
export function logoutSystem(data) {
    return request({
        url: '/logout',//请求路径
        method: 'get',
        // data
    });
}

/** 修改密码 */
export function updatePwd(data) {
    return request({
        url: '/system/user/updatePwd',//请求路径
        method: 'post',
        data
    });
}

/** 修改个人信息 */
export function editInfo(data) {
    return request({
        url: '/system/user/edit',//请求路径
        method: 'post',
        data
    });
}


/**
 *          角色
 */
/** 角色列表 */
export function roleList(data) {
    return request({
        url: '/role/list',//请求路径
        method: 'post',
        data
    });
}

/** 新增角色 */
export function addRole(data) {
    return request({
        url: '/role/add',//请求路径
        method: 'post',
        data
    });
}

/** 删除角色 */
export function delRole(data) {
    return request({
        url: '/role/del/'+data,//请求路径
        method: 'get',
        // data
    });
}

/** 编辑角色 */
export function editRole(data) {
    return request({
        url: '/role/edit',//请求路径
        method: 'post',
        data
    });
}

/** 权限分配 */
export function allotRolePermisson(data) {
    return request({
        url: '/role/allotRolePermisson',//请求路径
        method: 'post',
        data
    });
}


/**
 *          组织
 */
/** 组织列表 */
export function organizationList(data) {
    return request({
        url: '/organization/list',//请求路径
        method: 'post',
        data
    });
}

/** 新增组织 */
export function addOrganization(data) {
    return request({
        url: '/organization/add',//请求路径
        method: 'post',
        data
    });
}

/** 删除组织 */
export function delOrganization(data) {
    return request({
        url: '/organization/del/'+data,//请求路径
        method: 'get',
        // data
    });
}

/** 编辑组织 */
export function editOrganization(data) {
    return request({
        url: '/organization/edit',//请求路径
        method: 'post',
        data
    });
}

/** 组织树 */
export function organizationTree(data) {
    return request({
        url: '/rbac/org/listTreeOrg',//请求路径
        method: 'get',
        params:data
    });
}


/**
 *          权限
 */
/** 权限列表 */
export function permissonList(data) {
    return request({
        url: '/permission/list',//请求路径
        method: 'post',
        data
    });
}

/** 新增权限 */
export function addPermisson(data) {
    return request({
        url: '/permission/add',//请求路径
        method: 'post',
        data
    });
}

/** 删除权限 */
export function delPermisson(data) {
    return request({
        url: '/permission/del/'+data,//请求路径
        method: 'get',
        // data
    });
}

/** 编辑权限 */
export function editPermisson(data) {
    return request({
        url: '/permission/edit',//请求路径
        method: 'post',
        data
    });
}