<template>
    <div >
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="关键字" prop="keyword">
                                <el-input class="input-w290-h32" v-model="listQuery.keyword" placeholder="请输入手机号/车主/订单" @keyup.enter.native="getList('search')"></el-input>
                            </el-form-item>
                            <el-form-item label="时间范围" prop="startTime"  class="clear-margin-right">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.startTime" type="datetime" placeholder="请选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDateAlart"></el-date-picker>
                                <span class="time-interval">至</span>
                            </el-form-item>
                            <el-form-item label="" prop="endTime">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.endTime" type="datetime" 
                                    placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerEndDateAlart"></el-date-picker>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item style="display:none">
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="resetForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="box clearfix">
            <div class="left" v-if="showFlag">
                <div>选择组织机构</div>
                <Tree :nodeClick="currentNode" ></Tree>
            </div>
            <div :class='[showFlag?"":"noLeft","right"]'>
                <div class="table-list">
                    <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize"
                    :orderBy.sync="listQuery.orderField" :sortIndex.sync="listQuery.sort" @pagination="getList"></Table>
                </div>
            </div>
        </div>
        <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
  </div>
</template>
<script>
    import Tree from "@/components/tree/tree";
    import Table from '@/components/table/table';
    import {listPageOrderInfo,exportOrderInfo} from "@/api/orderManagement";
    import {getOrgId} from '@/utils/cookie';
    export default {
        components: {
            Table,
            Tree
        },
        data() {
            return {
                showFlag:true,
                pickerStartDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                        if (this.listQuery.endTime) {
                            if(this.listQuery.endTime.split(' ')[1] ==  '00:00:00') {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime() - 8.64e7|| time.getTime() > Date.now();
                            } else {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime() - 8.64e7|| time.getTime() > Date.now();
                            }
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                // 结束时间
                pickerEndDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                    
                        if (this.listQuery.startTime) {
                            if( this.listQuery.startTime.split(' ')[1] ==  '00:00:00' || this.listQuery.startTime.split(' ')[0] == this.format(new Date(),'-').split(' ')[0]) {
                                return  time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() || time.getTime() > Date.now();
                            }  else {
                                return time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() || time.getTime() > Date.now();
                            }
                            
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                /* end*/
                //搜索栏数据
                listQuery:{
                    keyword: '',
                    startTime: '',//开始时间 
                    endTime: '',//结束时间 
                    orgId:'',
                    total:10000,//数据条数
                    pageIndex: 1,//当前查询页面
                    pageSize: 10,//每页数据数量
                    orderField:null,
                    sort:''
                },
                //表格数据
                table: {
                    configItem: true, //是否开启表格项配置
                    data: [],
                    //表头按钮
                    operateBtns:[
                        {
                            value:'导出EXCEL',
                            type:'primary',
                            event:() => {
                                this.exportOrderInfo()
                            }
                        },
                    ],
                    header: [
                        {
                            type: 'index',
                            label: '序号',
                            width: '80',
                            ifShow: true,
                        },
                        // {
                        //     prop: 'id',
                        //     label: 'id',
                        //     ifShow: false,
                        // },
                        {
                            prop: 'orderCode',
                            label: '订单号',
                            minWidth: '150',
                            ifShow: true
                        },
                        {
                            prop: 'imei',
                            label: '挪车码',
                            minWidth: '150',
                            ifShow: true
                        },
                        {
                            prop: 'phone',
                            label: '手机号',
                            minWidth: '150',
                            tooltip: true,
                            ifShow: true,
                        },
                        {
                            prop: 'driverName',
                            label: '车主',
                            minWidth: '100',
                            ifShow: true
                        },
                        {
                            prop: 'carBrand',
                            label: '车牌号',
                            minWidth: '180',
                            ifShow: true
                        },{
                            prop: 'purchase',
                            label: '续费时长',
                            minWidth: '120',
                            ifShow: true
                        },{
                            prop: 'createTime',
                            label: '续费时间',
                            minWidth: '190',
                            ifShow: true
                        },{
                            prop: 'orderState',
                            label: '订单状态',
                            minWidth: '120',
                            ifShow: true,
                            render: (h,param) => {
                                var text = "";
                                if(param.orderState == 1){
                                    text= '未支付'
                                }else if(param.orderState == 2){
                                    text= '已支付'
                                }
                                return h('div', {},text)
                            }
                        },{
                            prop: 'createTime',
                            label: '订单完成时间',
                            minWidth: '190',
                            ifShow: true
                        },{
                            prop: 'money',
                            label: '订单金额',
                            minWidth: '180',
                            ifShow: true
                        }
                    ],
                },
            }
        },
        mounted() {
            let s = getOrgId();
            if(s != 1){
                this.listQuery.orgId = getOrgId();   
                this.showFlag = false;
            }
        },
        created(){
            this.getList();
        // this.getList2()
        },
        methods: {
            currentNode(data) {
                this.listQuery.orgId = data.orgId;
                this.getList();
            },
            getList(val){
                if(val == "search"){
                    this.listQuery.pageIndex =1 ;
                }
                listPageOrderInfo(this.listQuery).then(res => {
                    // console.log(res)
                    this.table.data = res.data;
                    this.listQuery.total = res.count; 
                })
            },
            resetForm(formName) { 
                this.$refs[formName].resetFields();
                if(formName == 'form1'){
                this.dialogForm1Visible = false
                }else{
                this.dialogForm2Visible = false;
                }
            },
            /** 导出文件 */
            exportOrderInfo() {
                exportOrderInfo(this.listQuery).then( res => {
                    // 使用blob下载文件
                    const blob = new Blob([res]);
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    // 转换文件文字为中文
                    downloadElement.download = '订单记录.xlsx';
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
// .box{
//   overflow: auto;
//   max-height: 100px;
//   margin-top: 20px;
// }
// .box div{
//   color: #fff;
//   float: left;
//   margin-right: 5px;
//   margin-bottom: 5px;
//   padding: 2px 4px;
//   font-size: 12px;
//   border-radius: 2px;
//   background-color: #409EFF;
// }
.clearfix:after{
     content:".";        
     display:block;        
     height:0;        
     clear:both;        
     visibility:hidden;        
}
.left {
    float: left;
    width: 300px;
    background-color: #fff;
    border-radius: 5px;
    // min-height: 500px;
    // border: 1px solid #487efd;
    padding: 10px;
}
.right {
    margin-left: 40px;
    float: left;
    width:calc(100% - 360px); 
    // height: 100%;
}
.right.noLeft{
    width:100%;
    margin-left: 0;
}
</style>
