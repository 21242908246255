<template>
    <div >
        <el-input
            class="input"
            :placeholder="placeholderVal"
            size="small"
            prefix-icon="el-icon-search"
            v-model="filterVal" clearable :validate-event="false">
        </el-input>
        <div class="organization-tree" :style="fatherStyle">
            <div class="tree-view">
                <el-tree ref="organizationTree" :data="data ? data : treeData"
                :default-expanded-keys="defaultExpanded" :default-checked-keys="defaultCheck" :props="defaultProps" node-key="id" :expand-on-click-node="expandNode ? expandNode : false"
                highlight-current :filter-node-method="filterNode" @node-click="nodeClick" :empty-text="nullText"></el-tree>
            </div>
        </div>
    
    </div>

</template>

<script>
import { organizationTree } from '@/api/systemSetting';
export default {
  name: 'organizationalTree',
  props:{
        nodeClick:{
            type:Function
        },
        data:{
            type:Array
        },                                                                                    
        placeholder:{
            type:String
        },
        expandNode:{
            type:Boolean
        },
        fatherStyle:{
            type:String
        },
        sendDefaultCheck:{
            type:Array,
            default: () => {
                return []
            }
        }
    },
  data() {
        return {
            treeName:'组织树',
            filterVal:'',
            treeData:[],
            defaultExpanded:this.sendDefaultCheck,
            defaultCheck:this.sendDefaultCheck,
            defaultProps: {
                children: 'children',
                label: 'orgName'
            },
        }
  },
  created() {
      this.getUserTreeInfo();
  },
  mounted() {
    /** 回显选中的树节点 */
    // setTimeout(()=>{
    //     this.$nextTick(function(){
    //         this.$refs.organizationTree.setCurrentKey(this.defaultCheck[this.defaultCheck.length-1]);
    //     })
    // },50)
  },
  computed:{
        placeholderVal:function(){
            return this.placeholder;
        },
        nullText: function(){
            return '暂无数据';
        },
    },		
  methods:{
      //获取数据
      getUserTreeInfo(){
          organizationTree({}).then(res => {
              this.treeData = res.data
            //   console.log(this.treeData)
          })
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.orgName.indexOf(value) !== -1;
         },
        /**
         * 递归遍历树的方法
         */
        getNode(nodeArr, nodeId){
            var result = null;
            if(!nodeArr){
                nodeArr = this.treeData;
            }
            if (nodeArr) {
                for (var i=0; i<nodeArr.length; i++) {
                    if (nodeArr[i].id === nodeId + "") {
                        return {id: nodeArr[i].id, attr: nodeArr[i].attr, name: nodeArr[i].name, code: nodeArr[i].code};
                    }
                    result = this.getNode(nodeArr[i].children, nodeId);
                    if (result) {
                        return {id: result.id, attr: result.attr, name: result.name, code: result.code};
                    }
                }
            }
        }
    },
   watch: {
        filterVal(val){
            this.$refs.organizationTree.filter(val);			
        }
    },
}
</script>

<style lang="scss" scoped>
    .organization-tree {
        padding: 0 15px 0 0;
        overflow:auto;
        width: 310px;
        // height:calc( 100% - 179px);
        height: 100%; 
        box-sizing: border-box;
    }
    // .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    //     background-color: #E6F9F0;
    //     color: #0CCC6C;
    //     width: 100%;
    //     line-height: 24px;
    // }
</style>
